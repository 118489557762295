import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { IconProfileCard, LinkedLogo, NamedLink } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import footerlogo from '../../../../assets/images/footerLogo.png';
import { FormattedMessage } from 'react-intl';
import facebooklogo from '../../../../assets/images/facebook.png';
import twitterlogo from '../../../../assets/images/twitter.png';
import linkedin from '../../../../assets/images/linkedin.png';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,

    copyright,
    blocks,
    options,
  } = props;
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;

  // use block builder instead of mapping blocks manually

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {/* <div className={css.footer}>
        <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div>
            <LinkedLogo rootClassName={css.logoLink} logoClassName={css.logoImage} />
          </div>
          <div className={css.sloganMobile}>
            <Field data={slogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              <Field data={slogan} className={css.slogan} />
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} options={options} />
              </div>
            ) : null}
            <Field data={copyright} className={css.copyright} />
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} options={options} />
          </div>
        </div>
      </div> */}

      <div className={css.sectionFooter}>
        <div className={css.footerLogo}>
          <img src={footerlogo} />
          {/* <p>Demo text. Strategic HR  play a critical role in helping organization align their human </p> */}
        </div>
        <div className={css.footerDetailSection}>
          <div className={css.linkSection}>Quick Links:
            <ul>
              <li></li>
              <li>
                <NamedLink name="LandingPage">
                  <FormattedMessage id="SectionFooter.home" />
                </NamedLink>
              </li>
              <li>
                <a href="/p/about">
                  <FormattedMessage id="SectionFooter.about" />
                </a>
              </li>
              <li>
                <NamedLink name="ContactUsPage">
                  <FormattedMessage id="SectionFooter.contact" />
                </NamedLink>
              </li>
              <li>
                <NamedLink name="TermsOfServicePage">
                <FormattedMessage id="SectionFooter.privacyPolicy"/>
                </NamedLink>
                </li>
                <li>
                <NamedLink name="TermsOfServicePage">
                <FormattedMessage id="SectionFooter.termsAndCondition"/>
                </NamedLink>
                </li>
            </ul>
          </div>
          {/* <div className={css.linkSection}>Service: */}
            {/* <ul> */}
              {/* <li>
                <NamedLink name="SearchPage">
                  <FormattedMessage id="SectionFooter.findExpert" />
                </NamedLink>
              </li> */}
              {/* <li> */}
                {/* <NamedLink name="BecomeAExpertPage">
                  <FormattedMessage id="SectionFooter.becomeExpert" />
                </NamedLink> */}
                {/* {!isAuthenticated ? <NamedLink className={css.createListingLink} name="BecomeAExpertPage">
          <span className={css.becomeExpertButton}>
          <FormattedMessage id="SectionFooter.becomeExpert" />
          </span>
        </NamedLink> :
          null
        } */}
              {/* </li> */}
            {/* </ul> */}
          {/* </div> */}
          <div className={css.linkSection}>
            Social:
            <div className={css.socialSiteLink}>
              <img src={facebooklogo} />
              <img src={twitterlogo} />
              <img src={linkedin} />
            </div>
          </div>

        </div>
      </div>
      <div className={css.copyrightText}>Copyright @ 2023 Advize Me</div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,

  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
